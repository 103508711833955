import { animated } from 'react-spring'
import styles from './Text.css'

export function TextPrimaryLg({ children, animation = undefined, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentPrimaryLg, styles.primary, layoutClassName)}
      {...{ children, animation }}
    />
  )
}

export function TextPrimaryMd({ children, animation = undefined, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentPrimaryMd, styles.primary, layoutClassName)}
      {...{ children, animation }}
    />
  )
}

export function TextPrimaryAlternateMd({ children, animation = undefined, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentPrimaryAlternateMd, styles.primary, layoutClassName)}
      {...{ children, animation }}
    />
  )
}

export function TextPrimaryAlternateSm({ children, animation = undefined, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentPrimaryAlternateSm, styles.primary, layoutClassName)}
      {...{ children, animation }}
    />
  )
}

export function TextSecondaryMd({ children, animation = undefined, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentSecondaryMd, styles.secondary, layoutClassName)}
      {...{ children, animation }}
    />
  )
}

export function TextPrimarySm({ children, animation = undefined, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentPrimarySm, styles.primary, layoutClassName)}
      {...{ children, animation }}
    />
  )
}

export function TextSecondarySm({ children, animation = undefined, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentSecondarySm, styles.secondary, layoutClassName)}
      {...{ children, animation }}
    />
  )
}

function TextBase({ children, animation, className }) {
  const Base = animation ? animated.p : 'p'

  return (
    <Base
      style={animation}
      className={cx(styles.componentBase, className)}
      {...{ children }}
    />
  )
}

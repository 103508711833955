import { TextPrimaryMd } from '/components/buildingBlocks/Text'
import { useCookieConfiguration } from '@kaliber/cookie-consent'
import { routeMap } from '/routeMap'
import { ButtonPrimary } from '/components/buildingBlocks/Button'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import ButtonLinkPrimary from '/components/buildingBlocks/ButtonLinkPrimary.universal'
import { reportClientError } from '/machinery/reportClientError'

import styles from './CookieBar.css'

export function CookieBar({ initialConfiguration, layoutClassName }) {
  const clientConfig = useClientConfig()
  const { cookieConfigurationUrl } = clientConfig

  const {
    acceptAll,
    configuration = initialConfiguration
  } = useCookieConfiguration({
    availablePermissions: clientConfig.cookieConfiguration.availablePermissions,
    cookieEndpoint: cookieConfigurationUrl,
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportClientError
  })

  const { show } = configuration

  if (show === false) return <div />

  return (
    <div className={cx(styles.component, layoutClassName)} data-style-context="dark">
      <div className={styles.content}>
        <TextPrimaryMd>This website uses cookies to ensure you get the best experience on our website.</TextPrimaryMd>
        <ButtonLinkPrimary href={routeMap.privacy()} layoutClassName={styles.readMoreButtonLayout}>
          Read more
        </ButtonLinkPrimary>
        <ButtonPrimary layoutClassName={styles.confirmationButtonLayout} onClick={acceptAll}>I get it</ButtonPrimary>
      </div>
    </div>
  )
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}

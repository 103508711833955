export function pushToDataLayer(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) throw new Error(`No data given to pushToDataLayer function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      console.error(e)
    }
  })
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}
